import { EventsCalendar } from '@/components/EventsCalendar';
import { FlowConduit, SlideOverFlowConduit, useCancelFlow, useStartFlow } from '@/lib/flow';
import PageHeading from 'frontend/src/components/PageHeading';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { RESERVATIONS_QUERY_KEY } from '../api/reservationsApi';
import { toast } from 'react-hot-toast';
import { DateTime } from 'luxon';
import { ReservationForm } from '@/form/ReservationForm';
import {
  CycleReservation,
  MiniReservation,
  Reservation,
  cycleReservationSchema,
  reservationSchema,
} from 'shared/src/schemas/reservation';
import {
  fetchReservation,
  fetchReservations,
  saveReservations,
} from 'frontend/src/services/reservationService';
import { generalTranslations } from '@/generalTranslations';
import { queryClient } from '@/lib/queryClient';
import { hasPermission } from 'frontend/src/layers/authorization/hasPermission';
import { currentBusinessId } from 'frontend/src/services/businessService';
import { fetchCurrentUser } from 'frontend/src/services/currentUserService';
import { useSearchParams } from 'react-router-dom';
import { CycleReservationForm } from '@/form/CycleReservationForm';
import ReservationFilters from '../components/filters';
import { mapCalendarEvents } from '../api/mapper';
import ChooseLessonTypeDialog from '@/pages/lessons/list/components/chooseLessonTypeDialog';
import { CycleLesson } from 'shared/src/schemas/lesson';

export default function ReservationsList() {
  const { formatMessage } = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({
    startDate: searchParams.get('startDate')
      ? new Date(searchParams.get('startDate')!)
      : new Date(),
    endDate: searchParams.get('endDate')
      ? new Date(searchParams.get('endDate')!)
      : new Date(),
    view: searchParams.get('view') || 'week',
    userIds: searchParams.get('userIds')
      ? searchParams.get('userIds')!.split(',')
      : [],
    locationIds: searchParams.get('locationIds')
      ? searchParams.get('locationIds')!.split(',')
      : [],
    lessonIds: searchParams.get('lessonIds')
      ? searchParams.get('lessonIds')!.split(',')
      : [],
    trainerIds: searchParams.get('trainerIds')
      ? searchParams.get('trainerIds')!.split(',')
      : [],
  });

  useEffect(() => {
    setSearchParams({
      ...filters,
      startDate: filters.startDate.toISOString(),
      endDate: filters.endDate.toISOString(),
      userIds: filters.userIds.join(','),
      locationIds: filters.locationIds.join(','),
      lessonIds: filters.lessonIds.join(','),
      trainerIds: filters.trainerIds.join(','),
    });
  }, [filters]);

  const startFlow = useStartFlow();
  const closeFlow = useCancelFlow();

  const ADD_RESERVATION_DIALOG = 'ADD_RESERVATION_DIALOG';
  const SHOW_CREDIT_RESERVATION_DIALOG = 'SHOW_CREDIT_RESERVATION_DIALOG';
  const SHOW_CYCLE_RESERVATION_DIALOG = 'SHOW_CYCLE_RESERVATION_DIALOG';


  const { mutate: submitUpdateReservation } = useMutation({
    mutationFn: saveReservations,
    onSuccess: () => {
      toast.success(formatMessage(t.reservationUpdated));
      queryClient.invalidateQueries({ queryKey: RESERVATIONS_QUERY_KEY });
      closeFlow();
    },
    onError: (error) => {
      console.error(error);
      toast.error(formatMessage(generalTranslations.somethingWentWrong));
    },
  });
  const cancelReservations = async (reservations: Reservation[]) => {
    console.log(`About to cancel ${reservations.length} reservations`);
    await submitUpdateReservation(
      reservations.map((reservation) => ({
        ...reservation,
        cancelled: true,
      }))
    );
  };
  const { data: reservations, error } = useQuery({
    queryKey: [...RESERVATIONS_QUERY_KEY, JSON.stringify(filters)],
    initialData: [],
    queryFn: async () => {
      return fetchReservations({
        start: filters.startDate,
        end: filters.endDate,
        locationIds: filters.locationIds,
        userIds: filters.userIds,
        lessonIds: filters.lessonIds,
        trainerIds: filters.trainerIds,
      });
    },
    enabled:
      DateTime.fromJSDate(filters.startDate)
        .diff(DateTime.fromJSDate(filters.endDate))
        .toMillis() !== 0,
  });
  if (error) {
    console.error(error);
    toast.error(
      formatMessage(generalTranslations.failedToFetch, {
        resource: formatMessage(t.reservations).toLowerCase(),
      })
    );
  }

  const getAllReservationsFromMaster = (
    masterReservation: CycleReservation
  ) => {
    const neededReservations: Reservation[] = [];
    for (const miniReservation of masterReservation.reservations) {
      const reservation = reservations?.find(
        (x) => x.id === miniReservation.id
      );

      neededReservations.push(reservation as Reservation);
    }
    return neededReservations.filter(x => !!x);
  };
  return (
    <>
      <PageHeading
        title={`${formatMessage(t.title)}`}
        actions={[
          {
            label: formatMessage(t.create),
            action: () => {
              startFlow({
                name: ADD_RESERVATION_DIALOG,
                payload: {},
              });
            },
            requiredPermission: 'reservations.write',
          },
        ]}
      />
      <ReservationFilters
        setFilters={setFilters}
        filters={filters}
      />
      <EventsCalendar
        events={mapCalendarEvents(reservations)}
        onCreation={async ({ start, end }) => {
          if (
            !hasPermission(
              'reservations.write',
              currentBusinessId(),
              await fetchCurrentUser()
            )
          ) {
            return;
          }
          startFlow({
            name: ADD_RESERVATION_DIALOG,
            payload: {
              start,
              end,
            },
          });
        }}
        onSelection={({ eventId }) => {
          if (eventId.startsWith('cycle-')) {
            const mappedEvents = mapCalendarEvents(reservations);
            console.log(mappedEvents.find((event) => event.id === eventId));
            startFlow({
              name: SHOW_CYCLE_RESERVATION_DIALOG,
              payload: {
                event: mappedEvents.find((event) => event.id === eventId),
              },
            });
            return;
          }
          startFlow({
            name: SHOW_CREDIT_RESERVATION_DIALOG,
            payload: {
              reservation: reservations?.find(
                (reservation) => reservation.id === eventId
              ),
            },
          });
        }}
        defaultView={{
          start: filters.startDate,
          end: filters.endDate,
          view: filters.view,
        }}
        onViewChange={({ startDate, endDate, view }) => {
          setFilters({
            ...filters,
            startDate,
            endDate,
            view,
          });
        }}
      />
      <FlowConduit
        flowMapping={{
          [ADD_RESERVATION_DIALOG]: (params) => {
            const defaultRawValues = {
              // @ts-ignore
              start: params.start,
              // @ts-ignore
              end: params.end,
            };

            return (
              <ChooseLessonTypeDialog
                onCreditClick={() => {
                  startFlow({ name: SHOW_CREDIT_RESERVATION_DIALOG, payload: { reservation: defaultRawValues } });
                }}
                onCycleClick={() => {
                  startFlow({ name: SHOW_CYCLE_RESERVATION_DIALOG, payload: { reservation: defaultRawValues } });
                }}
              />
            );
          },
        }}
      />
      <SlideOverFlowConduit
        title={formatMessage(t.title)}
        flowMapping={{
          [SHOW_CREDIT_RESERVATION_DIALOG]: (params) => {
            //@ts-ignore
            const defaultValues = params.reservation;
            console.log(defaultValues);
            return (
              <ReservationForm
                defaultValues={defaultValues}
                onSubmit={(reservation) =>
                  submitUpdateReservation([reservation])
                }
                onDelete={(reservation) => cancelReservations([reservation])}
              />
            );
          },
          [SHOW_CYCLE_RESERVATION_DIALOG]: (params) => {
            //@ts-ignore
            const event = params.event;
            let defaultValues = {}
            if (event) {

              const eventReservations: fetchReservation[] =
                event.reservations.map((x: MiniReservation) => {
                  const reservation = reservations?.find(
                    (reservation) => reservation.id === x.id
                  );
                  if (!reservation) {
                    throw new Error('Could not find reservation');
                  }
                  return reservation;
                });
              const firstReservation = eventReservations[0];
              if (!firstReservation) {
                throw new Error('Could not find first reservation');
              }
              const eventReservationTrainers = eventReservations
                .map((x) => x.trainers)
                .flat()

              const uniqueTrainersBasedOnId = Array.from(
                new Set(eventReservationTrainers.map((x) => x?.id))
              ).map((id) => eventReservationTrainers.find((x) => x?.id === id));
              defaultValues = cycleReservationSchema.parse({
                ...event,
                cycle: firstReservation.cycle,
                feedback: firstReservation.feedback,
                lesson: firstReservation.lesson,
                location: firstReservation.location,
                trainers:
                  uniqueTrainersBasedOnId && uniqueTrainersBasedOnId.length
                    ? uniqueTrainersBasedOnId
                    : undefined,
                start: firstReservation.start,
                end: firstReservation.end,
              });
              console.log({ defaultValues });
            }
            return (
              <CycleReservationForm
                defaultValues={defaultValues}
                onSubmit={(masterReservation) => {
                  console.log({ masterReservation });
                  let allReservations = getAllReservationsFromMaster(masterReservation);
                  console.log({ allReservations })
                  if (!allReservations.length) {
                    const selectedCycle = (masterReservation.lesson as CycleLesson).cycles.find(c => c.id === masterReservation.cycle)
                    if (!selectedCycle) {
                      throw new Error('Could not find selected cycle')
                    }
                    for (const cycleDate of selectedCycle.cycleDates) {
                      const index = selectedCycle.cycleDates.findIndex((x) => x.date === cycleDate.date)
                      const start = cycleDate.date as Date

                      // calculate end by adding masterReservation.lesson.duration to start
                      const end = DateTime.fromJSDate(start).plus({ hours: masterReservation.lesson.duration }).toJSDate()
                      const reservationsForThisDate = masterReservation.reservations.map((x) => {
                        console.log({ x, start, end })
                        return reservationSchema.parse({
                          cancelled: x.cancelled,
                          id: `${x.id}-${x.user.id}-${index}`,
                          user: x.user,
                          person: x.personJoining || undefined,
                          lesson: masterReservation.lesson,
                          cycle: masterReservation.cycle,
                          location: selectedCycle.location,
                          trainers: masterReservation.trainers || undefined,
                          start,
                          end,
                          order: masterReservation.order,
                          feedback: masterReservation.feedback,
                          personalFeedback: x.personalFeedback,
                          personalTrainers: x.personalTrainers || [],
                        })
                      })
                      allReservations = [...allReservations, ...reservationsForThisDate]
                    }

                  }

                  console.log({ allReservations })

                  const updatedReservations = allReservations.map((x, i) => {
                    console.log({ x, i: masterReservation.reservations[i] })
                    const updatedReservation = masterReservation.reservations[i]
                    return {
                      ...x,
                      lesson: masterReservation.lesson,
                      trainers: masterReservation.trainers,
                      feedback: masterReservation.feedback,
                      personalFeedback: updatedReservation ? updatedReservation.personalFeedback : x.personalFeedback,
                      user: updatedReservation ? updatedReservation.user : x.user,
                      person: updatedReservation ? updatedReservation.personJoining : x.person,
                      cancelled: updatedReservation ? updatedReservation.cancelled : x.cancelled,
                      personalTrainers: updatedReservation ? updatedReservation.personalTrainers : x.personalTrainers,
                    };
                  });
                  console.log({ updatedReservations });
                  submitUpdateReservation(updatedReservations);
                }}
                onDelete={(masterReservation) => {
                  cancelReservations(
                    getAllReservationsFromMaster(masterReservation)
                  );
                }}
              />
            );
          },
        }}
      />
    </>
  );
}

const t = defineMessages({
  title: {
    id: 'reservations-list-title',
    defaultMessage: 'Reservations',
  },
  create: {
    id: 'reservations-list-create',
    defaultMessage: 'Add reservation',
  },
  reservations: {
    id: 'reservations-list-reservation',
    defaultMessage: 'reservations',
  },
  reservationUpdated: {
    id: 'reservations-list-reservationUpdated',
    defaultMessage: 'Reservation updated',
  },
});
