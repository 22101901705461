import { initializeApp, getApps, FirebaseApp } from 'firebase/app';
import {
  getFirestore as getFirebaseFirestore,
  connectFirestoreEmulator,
  Firestore,
} from 'firebase/firestore';
import {
  getStorage as getFirebaseStorage,
  FirebaseStorage,
  connectStorageEmulator,
} from 'firebase/storage';
import {
  getAuth as getFirebaseAuth,
  connectAuthEmulator,
  Auth,
} from 'firebase/auth';
import {
  getRemoteConfig as getFirebaseRemoteConfig,
  RemoteConfig,
} from 'firebase/remote-config';
import {
  getPerformance as getFirebasePerformance,
  FirebasePerformance,
} from 'firebase/performance';
import {
  getAnalytics as getFirebaseAnalytics,
  Analytics,
} from 'firebase/analytics';
import {
  getFunctions as getFirebaseFunctions,
  connectFunctionsEmulator,
  Functions,
} from 'firebase/functions';
import {
  initializeAppCheck,
  ReCaptchaV3Provider,
  AppCheck,
} from 'firebase/app-check';

import { firebaseConfig, reCaptchaConfig } from '../../../../config';
declare global {
  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}
// get enum of keys of firebaseConfig
type apps = keyof typeof firebaseConfig;
let selectedApp: apps;


const useEmulator = false;// (typeof process !== 'undefined' && process.env && process.env.NEXT_PUBLIC_USE_EMULATORS) || (import.meta && import.meta.env && import.meta.env.VITE_USE_EMULATORS) || false;
const debugToken = "" // (typeof process !== 'undefined' && process.env && process.env.NEXT_PUBLIC_FIREBASE_APPCHECK_DEBUG_TOKEN) || (import.meta && import.meta.env && import.meta.env.VITE_FIREBASE_APPCHECK_DEBUG_TOKEN) || "";

const firebaseCache: {
  app?: FirebaseApp;
  firestore?: Firestore;
  storage?: FirebaseStorage;
  auth?: Auth;
  remoteConfig?: RemoteConfig;
  performance?: FirebasePerformance;
  analytics?: Analytics;
  functions?: Functions;
  appCheck?: AppCheck;
} = {};

// Initialize Firebase
export function initFirebase(currentApp: apps) {
  console.log('Initializing Firebase', { useEmulator, debugToken });
  selectedApp = currentApp;
  getFirebaseApp();
  getAppCheck();
}
function getFirebaseApp() {
  if (firebaseCache.app) return firebaseCache.app;

  const apps = getApps();

  if (apps.length) {
    const firebaseApp = apps[0];

    firebaseCache.app = firebaseApp;
    return firebaseApp;
  }

  if (!firebaseConfig) {
    throw new Error('Firebase config is missing');
  }

  const firebaseApp = initializeApp(firebaseConfig[selectedApp]);
  firebaseCache.app = firebaseApp;
  getAppCheck();
  return firebaseApp;
}

export function getFirestore() {
  if (firebaseCache.firestore) return firebaseCache.firestore;

  const app = getFirebaseApp();
  const firestore = getFirebaseFirestore(app);
  if (useEmulator) {
    connectFirestoreEmulator(firestore, 'localhost', 8080);
  }

  firebaseCache.firestore = firestore;

  return firestore;
}

export function getStorage() {
  if (firebaseCache.storage) return firebaseCache.storage;

  const app = getFirebaseApp();
  const firebaseStorage = getFirebaseStorage(app);

  if (useEmulator) {
    connectStorageEmulator(firebaseStorage, 'localhost', 9199);
  }

  return firebaseStorage;
}

export function getAuth() {
  if (firebaseCache.auth) return firebaseCache.auth;

  const app = getFirebaseApp();
  const firebaseAuth = getFirebaseAuth(app);

  if (useEmulator) {
    console.log('Using auth emulator');
    connectAuthEmulator(firebaseAuth, 'http://localhost:9099');
  }

  firebaseCache.auth = firebaseAuth;
  return firebaseAuth;
}

export function getRemoteConfig() {
  if (firebaseCache.remoteConfig) return firebaseCache.remoteConfig;

  const app = getFirebaseApp();

  const firebaseRemoteConfig = getFirebaseRemoteConfig(app);

  firebaseCache.remoteConfig = firebaseRemoteConfig;
  return firebaseRemoteConfig;
}

export function getPerformance() {
  if (firebaseCache.performance) return firebaseCache.performance;

  const app = getFirebaseApp();
  const firebasePerformance = getFirebasePerformance(app);

  firebaseCache.performance = firebasePerformance;
  return firebasePerformance;
}

export function getAnalytics() {
  if (firebaseCache.analytics) return firebaseCache.analytics;

  const app = getFirebaseApp();
  const firebaseAnalytics = getFirebaseAnalytics(app);

  firebaseCache.analytics = firebaseAnalytics;
  return firebaseAnalytics;
}

export function getFunctions() {
  if (firebaseCache.functions) return firebaseCache.functions;

  const app = getFirebaseApp();
  const firebaseFunctions = getFirebaseFunctions(app, 'europe-west1');

  if (useEmulator) {
    connectFunctionsEmulator(firebaseFunctions, 'localhost', 5001);
  }

  firebaseCache.functions = firebaseFunctions;
  return firebaseFunctions;
}

export function getAppCheck() {
  if (firebaseCache.appCheck) return firebaseCache.appCheck;
  if (debugToken && typeof window !== "undefined") {
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = debugToken;
  }
  const app = getFirebaseApp();
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(
      reCaptchaConfig.siteKey
    ),
  });

  firebaseCache.appCheck = appCheck;
  return appCheck;
}

if (typeof window !== "undefined") {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).firebase = {
    initFirebase,
    getFirestore,
    getStorage,
    getAuth,
    getRemoteConfig,
    getPerformance,
    getAnalytics,
    getFunctions,
  };
}
