import { object, string, z } from 'zod';
import { personSchema, userSchema } from './users';
import { lessonSchema } from './lesson';
import { locationSchema } from './location';
import { orderSchema } from './orders';
import { firebaseDate } from './date';

export const feedBackSchema = z.object(
  {
    rating: z
      .number({
        description: 'The rating the user got in the lesson from the trainer',
      })
      .optional(),
    comment: z
      .string({
        description: 'The comment the user got in the lesson from the trainer',
      })
      .optional()
      .default(''),
  },
  {
    description: 'The feedback the user got in the lesson from the trainer',
    invalid_type_error: 'Invalid feedback data',
  }
);

export const reservationSchema = z.object({
  cancelled: z.boolean().default(false).optional(),
  id: z.string(),
  user: userSchema,
  person: personSchema.optional(),
  lesson: lessonSchema,
  cycle: string().optional(),
  location: locationSchema,
  present: z
    .boolean({
      description: 'Whether the user was present for the lesson',
    })
    .optional(),
  trainers: userSchema.array().optional().default(userSchema.array().parse([])),
  start: firebaseDate,
  end: firebaseDate,
  order: orderSchema.optional(),
  feedback: feedBackSchema.optional(),
  personalFeedback: feedBackSchema.optional(),
  personalTrainers: userSchema.array().optional().default(userSchema.array().parse([])),
});

export type Reservation = z.infer<typeof reservationSchema>;

export const miniReservationSchema = object(
  {
    id: string(),
    personJoining: personSchema.optional(),
    user: userSchema,
    cancelled: z.boolean().default(false).optional(),
    personalFeedback: feedBackSchema.optional(),
    personalTrainers: userSchema.array().optional().default([]),
  },
  {
    description: 'A reservation with minimal details',
    invalid_type_error: 'Invalid reservation data',
  }
);
export const cycleReservationSchema = reservationSchema
  .extend({
    reservations: z.array(miniReservationSchema).default([]),
    cycle: z.string(),
  })
  .omit({
    user: true,
    person: true,
    present: true,
    location: true,
  });
export type MiniReservation = z.infer<typeof miniReservationSchema>;
export type CycleReservation = z.infer<typeof cycleReservationSchema>;
