import { currentBusinessId } from '../services/businessService';
import { user } from '../../../../config';
export function getUserAppUrl(topDomain?: string) {
  if (user) {
    return user;
  }
  if (!topDomain) {
    topDomain = 'swimplannr.online';
  }
  const businessId = currentBusinessId();
  const isLocalhost = window.location.hostname === 'localhost';
  if (isLocalhost) {
    return 'http://localhost:3003';
  }
  return `https://${businessId}.${topDomain}`;
}