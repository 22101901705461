import { FlowConduitFactory, FlowMapping } from 'flow-conduit';
import React from 'react';
import SlideOver from 'frontend/src/layers/slide-over/SlideOver';
import { capitalizeFirstLetter } from 'shared/src/utils/stringHelpers';

export const { FlowProvider, FlowConduit, useStartFlow, useCancelFlow } =
  FlowConduitFactory();
FlowConduitFactory();

export function SlideOverFlowConduit({
  flowMapping,
  title,
}: {
  flowMapping: FlowMapping;
  title: string;
}) {
  const SlideOverFlowMapping: FlowMapping = Object.entries(flowMapping).reduce(
    (acc, [key, value]) => {
      acc[key] = (props) => (
        <SlideOverWrapper title={capitalizeFirstLetter(title)}>
          {value(props)}
        </SlideOverWrapper>
      );
      return acc;
    },
    {} as FlowMapping
  );

  return <FlowConduit flowMapping={SlideOverFlowMapping} />;
}

function SlideOverWrapper({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) {
  const cancelFlow = useCancelFlow();

  return (
    <SlideOver title={title} onClose={cancelFlow}>
      {children}
    </SlideOver>
  );
}
