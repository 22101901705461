import {
  ClockIcon,
  PencilIcon,
  TagIcon,
  LinkIcon,
  EyeSlashIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/20/solid';
import { saveLesson } from 'frontend/src/services/lessonService';
import { getUserAppUrl } from 'frontend/src/utils/getUserAppUrl';
import toast from 'react-hot-toast';
import { v4 as uuid } from 'uuid';
import { Lesson } from 'shared/src/schemas/lesson';


export default function SingleLessonView({
  lesson,
  onClick,
}: {
  lesson: Lesson;
  onClick: (lesson: Lesson) => void;
}) {
  const copyToClipboard = async () => {
    const baseUrl = getUserAppUrl(window.location.host.split('.').slice(-2).join('.'))

    const link = `${baseUrl}/lessons/${lesson.id}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        // You can add some logic here for a callback, like showing a notification
        toast.success(`Link copied for ${lesson.name}!`);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };
  const duplicateLesson = async (lesson: Lesson) => {
    console.log('Duplicate lesson:', lesson);
    const newLesson = {
      ...lesson,
      id: uuid(),
      name: `${lesson.name} (copy)`,
    }
    await saveLesson(newLesson)
    onClick(newLesson);
  }
  return (
    <div className="block hover:bg-gray-50 bg-white shadow sm:rounded-md w-full">
      <div onClick={() => onClick(lesson)} className="flex justify-between items-center p-4 cursor-pointer">
        <div className="flex-grow">
          <div className="flex items-center justify-between">
            <p className="truncate text-sm font-medium text-primary-600 flex gap-2">
              {lesson.name}
              <span className="font-extralight">{lesson.type}</span>
            </p>
          </div>
          <div className="flex mt-2 items-center text-sm text-gray-500 gap-4">
            <div className="flex gap-1">
              <ClockIcon
                className="h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {lesson.duration}
            </div>
            <div className="flex gap-1">
              <TagIcon
                className="h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {lesson.category.name}
            </div>
            {lesson.isHidden && (
              <EyeSlashIcon className="h-5 w-5 flex-shrink-0 text-gray-400" />
            )}
          </div>
        </div>

        <div className="flex space-x-2">

          <button
            className="flex items-center text-sm text-white bg-primary-500 hover:bg-primary-600 p-2 pr-1 rounded-lg"
          >
            <PencilIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0"
              aria-hidden="true"
            />
          </button>
          <button
            className="flex items-center text-sm text-gray-500 hover:bg-gray-200 hover:text-gray-700 p-2 rounded-lg"
            onClick={(e) => {
              e.preventDefault();
              duplicateLesson(lesson)
            }}
          >
            <DocumentDuplicateIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true" />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              copyToClipboard();
            }}
            className="flex items-center text-sm text-gray-500 hover:bg-gray-200 hover:text-gray-700 p-2 rounded-lg"
          >
            <LinkIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>
    </div>
  );
}
