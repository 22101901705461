import React from 'react';
import { Helmet } from 'react-helmet';
import { BUSINESS_BRANDING_QUERY_KEY, BUSINESS_QUERY_KEY } from '../api/keys';
import {
  getBusinessBranding,
  getCurrentBusiness,
} from '../services/businessService';
import { useQuery } from '@tanstack/react-query';

export function HelmetProvider({
  title,
  description,
  altLgo,
  showBusinessName = true,
}: {
  title?: string;
  description?: string;
  altLgo?: boolean;
  showBusinessName?: boolean;
}) {
  const { data: business, isLoading: isLoadingBusiness } = useQuery({
    queryKey: [...BUSINESS_QUERY_KEY],
    queryFn: getCurrentBusiness,
  });
  const { data: branding, isLoading: isLoadingBranding } = useQuery({
    queryKey: [...BUSINESS_BRANDING_QUERY_KEY],
    queryFn: () => getBusinessBranding(),
  });
  if (isLoadingBusiness || isLoadingBranding) return 'Loading...';

  const pageTitle = `${title ? title + ' | ' : ''} ${showBusinessName && business && business.name
      ? business?.name
      : 'Proswimlab'
    }`;
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content={business?.name} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:site" content={business?.name} />
        <meta name="twitter:creator" content={business?.name} />
        <meta name="theme-color" content={branding?.primaryColor} />
        <meta
          name="theme-color"
          content={branding?.secondaryColor}
          media="(prefers-color-scheme: dark)"
        />
        <link
          rel="icon"
          href={altLgo ? branding?.logoLoginScreen : branding?.logo}
        />
      </Helmet>
    </>
  );
}
