import { signInWithEmailLink, signInWithCustomToken, GoogleAuthProvider, FacebookAuthProvider, AuthProvider, signInWithPopup } from 'firebase/auth';
import { getAuth, getFunctions } from '../lib/firebase';
import { useQuery } from '@tanstack/react-query';
import { fetchUser } from './usersService';
import { httpsCallable } from 'firebase/functions';
import { currentBusinessId } from './businessService';

export async function signInWithGoogle() {
  const provider = new GoogleAuthProvider();
  provider.addScope('https://www.googleapis.com/auth/userinfo.email');
  return signInWithProvider(provider);
}

export async function signInWithFacebook() {
  const provider = new FacebookAuthProvider();
  provider.addScope('email');
  return signInWithProvider(provider);
}

async function signInWithProvider(provider: AuthProvider) {
  const auth = getAuth();
  return await signInWithPopup(auth, provider);
}


export async function sendSignInLink(email: string) {
  const url = new URL(window.location.href);
  const base64EncodedEmail = btoa(email);
  url.searchParams.set('email', encodeURI(base64EncodedEmail));
  url.searchParams.get('redirect') &&
    url.searchParams.set('redirect', url.searchParams.get('redirect')!);
  url.pathname = '/magic-link';

  try {
    const response = (await httpsCallable(
      getFunctions(),
      'auth-sendLoginLink'
    )({
      email,
      redirectUrl: url.toString(),
      businessId: currentBusinessId(),
    })).data as { mailSentId: string };
    return response.mailSentId;
  } catch (error) {
    console.log(error);
  }
}

export async function signInWithLink(email: string) {
  const auth = getAuth();
  await signInWithEmailLink(auth, email, window.location.href);
}

export function signOut() {
  const auth = getAuth();
  return auth.signOut();
}

export function getCurrentUser() {
  const auth = getAuth();
  return auth.currentUser;
}

export function useCurrentUser() {
  const { data: user, isFetched, isFetching } = useQuery({
    queryKey: ['currentUser'],
    initialData: null,
    queryFn: async () => {
      const firebaseUser = getCurrentUser();
      console.log('firebaseUser', firebaseUser);
      if (!firebaseUser) {
        return null;
      }
      const dbUSer = await fetchUser(firebaseUser.uid);
      console.log('dbUSer', dbUSer);
      return dbUSer;
    },
  });

  return { user, isFetching, isFetched };
}
export async function getToken() {
  const uid = getCurrentUser()?.uid;
  if (!uid) {
    throw new Error('No user logged in');
  }
  const tokenResponse = (await httpsCallable(
    getFunctions(),
    'auth-generateToken'
  )({
    uid,
  })) as {
    data: {
      token: string;
    };
  };
  return tokenResponse.data.token;
}
export async function signInWithToken(token: string) {
  if (getCurrentUser()) {
    return;
  }
  const auth = getAuth();
  await signInWithCustomToken(auth, token);
  return;
}
