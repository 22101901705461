import { z } from 'zod';

export const brandingSchema = z.object({
  primaryColor: z.string().optional().default('#3498db'),
  secondaryColor: z.string().optional().default('#2c3e50'),
  logo: z.string().optional().default('general/logoLight.svg'),
  logoLoginScreen: z.string().optional().default('general/logoDark.svg'),
  loginImage: z.string().optional().default('general/loginImage.jpeg'),
});
export type Branding = z.infer<typeof brandingSchema>;
