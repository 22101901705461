import { z } from 'zod';

export const businessSchema = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string(),
  street: z.string(),
  number: z.string(),
  mailbox: z.string().optional().default(''),
  zipCode: z.string(),
  city: z.string(),
  phone: z.string(),
  vat: z.string(),
  iban: z.string(),
  bic: z.string(),
  paymentTime: z.number(),
  remarks: z.string().optional().default(''),
  stripeAccountId: z.string(),
  stripeAccountFinished: z.boolean(),
  fee: z.number().optional().default(0.02),
  timeZone: z.string().optional().default('Europe/Brussels'),
  locale: z.string().optional().default('nl-BE'),
  assistToken: z
    .string()
    .optional()
    .default('262b2456-d094-4233-ad54-3076bb96a803'),
  forwardInvoiceEmail: z.string().default(''),
});
export type Business = z.infer<typeof businessSchema>;
