import { ReactNode, useRef, useState } from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Label } from '@headlessui/react';
import {
  FieldValues,
  Path,
  useController,
  useFormContext,
} from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';

export interface Option<T> {
  value: T | T[];
  key: string;
  label: string;
}

export interface Props<T, TFieldValues extends FieldValues> {
  name: Path<TFieldValues>;
  options: Option<T>[];
  label: ReactNode | string;
  buttonRender?: (option: Option<T> | undefined) => ReactNode;
  optionRender?: (option: Option<T>) => ReactNode;
  disabled?: boolean;
  searchFn?: (query: string) => void;
  multiple?: boolean;
  customSorting?: (a: Option<T>, b: Option<T>) => number;
}

function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(' ');
}

export function GenericComboBox<T, TFieldValues extends FieldValues>({
  name,
  options,
  label,
  optionRender,
  disabled,
  searchFn,
  multiple = false,
  customSorting,
}: Props<T, TFieldValues>) {
  const { formatMessage } = useIntl();
  const { control } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [query, setQuery] = useState<string | null>(null);
  const [openOptions, setOpenOptions] = useState(false);

  document.addEventListener('mousedown', (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
      setOpenOptions(false)
    }
  })

  const filteredOptions =
    query === null || searchFn
      ? options
      : options.filter((option: Option<T>) => {
        return option.label.toLowerCase().includes(query.toLowerCase());
      });

  const inputValue = multiple
    ? query !== null
      ? query
      : value
        ?.map((v: string) => options.find((o) => o.key === v))
        ?.map((x: Option<T>) => x?.label)
        .join(', ') || formatMessage(t.select)
    : query === null
      ? options.find((option) => option.value === value)?.label || formatMessage(t.select)
      : query

  const optionIsSelected = (option: Option<T>) => {
    if (multiple) {
      return value?.includes(option.value);
    }
    return value === option.value;
  };

  const orderOptions = (a: Option<T>, b: Option<T>) => {
    if (optionIsSelected(a)) {
      return -1;
    }
    if (optionIsSelected(b)) {
      return 1;
    }
    return a.label.localeCompare(b.label);
  };
  return (
    <div>
      <Combobox
        multiple={multiple ? false : undefined}
        name={name}
        disabled={disabled}
        value={value}
        onChange={(e: any) => {
          if (!e) {
            onChange(null);
            return
          }
          if (multiple) {
            if (!value) {
              onChange([e.value]);
              return;
            }
            if (value.includes(e.value)) {
              value.splice(value.indexOf(e.value), 1);
            } else {
              value.push(e.value);
            }
            onChange(value);
          } else {
            onChange(e.value);
          }
          setQuery(null);
        }}
      >
        <Label className="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </Label>
        <div className="relative" ref={menuRef}>
          <ComboboxInput
            value={inputValue}
            className="disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
            onChange={(event) => {
              setQuery(event.target.value);
              if (searchFn) {
                searchFn(event.target.value);
              }
            }}
            onClick={(e) => {
              e.currentTarget.select()
              setOpenOptions(true)
            }}
          />
          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none" onClick={() => setOpenOptions(!openOptions)}>
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </ComboboxButton>
          {filteredOptions.length > 0 && (
            <ComboboxOptions static={openOptions} className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredOptions
                .sort(customSorting || orderOptions)
                .map((option, index) => {
                  return (
                    <ComboboxOption
                      key={index}
                      value={option}
                      onClick={() => {
                        setOpenOptions(multiple)
                      }}
                      className={({ active }) =>
                        classNames(
                          'relative cursor-pointer select-none py-2 pl-3 pr-9',
                          active ? 'bg-secondary-600 text-white' : 'text-gray-900'
                        )
                      }
                    >
                      {({ active }) => (
                        <>
                          <span
                            className={classNames(
                              'block truncate',
                              optionIsSelected(option) && 'font-semibold'
                            )}
                          >
                            {optionRender ? optionRender(option) : option.label}
                          </span>

                          {optionIsSelected(option) && (
                            <span
                              className={classNames(
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                active ? 'text-white' : 'text-secondary-600'
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          )}
                        </>
                      )}
                    </ComboboxOption>
                  );
                })}
            </ComboboxOptions>
          )}
        </div>
      </Combobox>
    </div>

  );
}
const t = defineMessages({
  select: {
    id: 'GenericComboBox-select',
    defaultMessage: 'Make a selection',
  },
});
