import { z } from 'zod';
import { firebaseDate } from './date';

export const listOfModules = [
  'dashboard',
  'categories',
  'users',
  'lessons',
  'products',
  'locations',
  'availabilities',
  'reservations',
  'orders',
  'invoices',
  'branding',
  'assist',
  'email',
  'bookkeepingemail',
  'facebooklogin',
  'googlelogin',
  'test'
] as const;

export const basicModules: ModuleName[] = [
  'dashboard',
  'categories',
  'users',
  'lessons',
  'products',
  'locations',
  'availabilities',
  'reservations',
  'orders',
  'invoices',
  'branding'
] as const;

export const moduleNameSchema = z.enum(listOfModules);

export const moduleSchema = z.object({
  id: moduleNameSchema,
  activatedOn: firebaseDate,
});

export type Module = z.infer<typeof moduleSchema>;
export type ModuleName = z.infer<typeof moduleNameSchema>;
