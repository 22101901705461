import { FieldValues } from 'react-hook-form';
import { Props, ShortTextInput } from '../input/ShortTextInput';
import { FormField, FormFieldProps } from '../FormField';

export interface ShortTextFieldProps<TFieldValues extends FieldValues>
  extends Omit<FormFieldProps<TFieldValues>, 'className' | 'children'>,
  Props<TFieldValues> { }

export function ShortTextField<TFieldValues extends FieldValues>({
  name,
  prefix,
  postfix,
  disabled,
  hint,
  label,
}: ShortTextFieldProps<TFieldValues>) {
  return (
    <FormField label={label} hint={hint} name={name}>
      <ShortTextInput
        name={name}
        prefix={prefix}
        postfix={postfix}
        disabled={disabled}
      />
    </FormField>
  );
}
