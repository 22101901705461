import {
  and,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  Query,
  QueryCompositeFilterConstraint,
  QueryConstraint,
  QueryDocumentSnapshot,
  QueryFilterConstraint,
  setDoc,
  startAfter,
  where,
} from 'firebase/firestore';
import { getFirestore, getFunctions } from '../lib/firebase';
import { collectionNames } from 'shared/src/collectionNames';
import { Branding, brandingSchema } from 'shared/src/schemas/branding';
import { Business, businessSchema } from 'shared/src/schemas/business';
import { getBusinessIdFromOrigin } from '../utils/getBusinessId';
import { httpsCallable } from 'firebase/functions';
import { createContext, useContext } from 'react';
import { Module, moduleSchema } from 'shared/src/schemas/modules';

export function currentBusinessId(): string {
  // If businessId is in the query params, use that
  const url = new URL(window.location.href);
  const businessId = url.searchParams.get('businessId');
  if (businessId) {
    return businessId;
  }

  const hostname = url.hostname;
  return getBusinessIdFromOrigin(hostname);
}

export async function getCurrentBusiness(): Promise<Business> {
  const businessId = currentBusinessId();

  const q = doc(getFirestore(), collectionNames.business, businessId);
  const documentSnapShot = await getDoc(q);

  return businessSchema.parse({
    ...documentSnapShot.data(),
    id: documentSnapShot.id,
  });
}

export async function updateCurrentBusiness(business: Business) {
  const businessId = currentBusinessId();

  const q = doc(getFirestore(), collectionNames.business, businessId);
  await setDoc(q, business);
}

export async function createBusinessQuery<T>(
  qry: Query<T>,
  ...queryConstraints: QueryConstraint[]
): Promise<Query<T>> {
  const businessId = currentBusinessId();
  return query(qry, where('businessId', '==', businessId), ...queryConstraints);
}

export async function createBusinessQueryComposite(
  qry: Query,
  limitNumber: number | undefined,
  orderByField: string | undefined,
  ...queryConstraints: QueryFilterConstraint[]
): Promise<Query> {
  let q = qry
  const businessId = currentBusinessId();
  if (orderByField) {
    q = query(q, orderBy(orderByField));
  }
  if (limitNumber) {
    q = query(q, limit(limitNumber));
  }
  q = query(q, and(where('businessId', '==', businessId), ...queryConstraints));
  return q;

}

export async function getBusinessBranding({
  superAdmin,
  business,
}: { superAdmin?: boolean; business?: string } = {}) {
  if (superAdmin) {
    return brandingSchema.parse({});
  }
  const businessId = currentBusinessId();

  const q = doc(
    getFirestore(),
    collectionNames.business,
    business || businessId,
    collectionNames.settings,
    collectionNames.branding
  );
  const documentSnapShot = await getDoc(q);
  if (!documentSnapShot.exists()) {
    return brandingSchema.parse({});
  }
  const branding = brandingSchema.parse(documentSnapShot.data());

  return branding;
}

export async function updateBusinessBranding(branding: Branding) {
  const businessId = currentBusinessId();

  const q = doc(
    getFirestore(),
    collectionNames.business,
    businessId,
    collectionNames.settings,
    collectionNames.branding
  );
  await setDoc(q, branding);
}

export async function appendBusiness<T extends DocumentData>(
  data: T
): Promise<T & { businessId: string }> {
  const businessId = currentBusinessId();

  return { ...data, businessId };
}

export async function getStripeConnectUrl(returnUrl: string): Promise<{
  url: string;
}> {
  const url = (await httpsCallable(
    getFunctions(),
    'business-createStripeConnectedAccount'
  )({ returnUrl })) as {
    data: {
      url: string;
    };
  };

  return {
    url: url.data.url,
  };
}

export async function getStripeConnectedStatus() {
  const status = (await httpsCallable(
    getFunctions(),
    'business-getStripeAccountStatus'
  )()) as {
    data: {
      connected: boolean;
    };
  };

  return {
    connected: status.data.connected,
  };
}

export const businessIdContext = createContext<Business['id']>('');

export function useBusinessId() {
  return useContext(businessIdContext);
}

export async function fetchCurrentlyActiveModules(bId?: string) {
  const businessId = bId || currentBusinessId();

  const q = collection(
    getFirestore(),
    collectionNames.business,
    businessId,
    collectionNames.modules
  );

  const filteredQuery = query(q, where('activatedOn', '<=', new Date()));

  const documentSnapShot = await getDocs(filteredQuery);
  if (documentSnapShot.empty) {
    return [] as Module[];
  }
  return documentSnapShot.docs.map((doc) =>
    moduleSchema.parse({
      ...doc.data(),
      id: doc.id,
    })
  );
}

export const pageSize = 10;
let pages: {
  page: number;
  lastVisible: QueryDocumentSnapshot<DocumentData> | undefined;
}[] = [];

export async function fetchBusinesses({ page = 0 }: { page?: number }) {
  const lastVisible = pages.find((p) => p.page === page - 1)?.lastVisible;
  const q = collection(getFirestore(), collectionNames.business);

  const paginatedQuery = query(
    q,
    ...(page && lastVisible ? [startAfter(lastVisible)] : []),
    limit(pageSize)
  );

  const documentSnapShot = await getDocs(paginatedQuery);
  pages = [
    ...pages,
    {
      page,
      lastVisible: documentSnapShot.docs[documentSnapShot.docs.length - 1],
    },
  ];

  if (documentSnapShot.empty) {
    return [] as Business[];
  }
  return documentSnapShot.docs.map((doc) => {
    return businessSchema.parse({
      ...doc.data(),
      id: doc.id,
    });
  });
}

export async function toggleModule(module: string, businessId: string) {
  let active = true;
  const q = doc(
    getFirestore(),
    collectionNames.business,
    businessId,
    collectionNames.modules,
    module
  );
  if ((await getDoc(q)).exists()) {
    await deleteDoc(q);
    active = false;
  } else {
    await setDoc(q, { activatedOn: new Date() });
  }
  return { module, active };
}
