import { currentBusinessId } from '../services/businessService';
import { authUrl } from '../../../../config';

export default function getAuthUrlForThisApp() {
  const path = window.location.href.replace(window.location.protocol, '');
  const url = authUrl || getAuthUrl(path);

  const businessId = currentBusinessId();

  const separator = url.includes('?') ? '&' : '?';
  const authUrlWithRedirect = `${url}${separator}businessId=${businessId}&redirect=${path.replace(
    window.location.protocol,
    ''
  )}`;

  return authUrlWithRedirect;
}

function getAuthUrl(hostname: string) {
  const host = hostname.replace(window.location.protocol, '');
  // If hostname is localhost, return localhost:3000
  if (host.includes('localhost')) {
    return '//localhost:3000';
  }
  return authUrl.replace('https:', '');
}
