export function submitForm(element: HTMLElement | null | undefined) {
  if (!element) return;

  const form =
    element.nodeName === 'FORM'
      ? element
      : element.querySelector('form:not([data-no-submit="true"])');
  if (!form) return;

  const existingSubmitButton = form.querySelector<HTMLButtonElement>(
    'button[type="submit"]'
  );

  if (existingSubmitButton) {
    existingSubmitButton.click();
    return;
  }

  const submitButton = document.createElement('button');
  submitButton.type = 'submit';
  form.appendChild(submitButton);
  submitButton.click();
  submitButton.remove();
}
